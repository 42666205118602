(function () {
    'use strict';

    angular.module('aerosApp')
        .directive('aflPresetSelect', aflPresetSelect);

    aflPresetSelect.$inject = [];

    function aflPresetSelect() {
        var directive = {
            restrict: 'E',
            templateUrl: '/static/templates/directive/afl-preset-select.html',
            controller: aflPresetSelectController,
            scope: {
                presets: '=',
                preset: '=',
                group: '=',
                toolModel: '=',
                selectedPreset: '=',
                onChange: '&'
            }
        };

        return directive;
    }

    aflPresetSelectController.$inject = ['$scope'];

    function aflPresetSelectController($scope) {
    }
})();
